<template>
  <v-icon :color="iconColorClass">
    {{ iconName }}
  </v-icon>
</template>

<script>
// design
import { iconCategories } from "@/design/icon/iconConst";

// mixins
import { menuItemSettingsMixin } from "@/mixins/shared/base/settings/menuItemSettingsMixin";
import { findCategoryTypeColorClass } from "@/model/category/categoryModel";

export default {
  name: "CategoryIcon",
  mixins: [menuItemSettingsMixin],
  data() {
    return {
      iconName: iconCategories
    };
  },
  props: {
    /**
     * @type {{id:number, name:string, formId:number, flags:number, categoryType: number, categoryTypeName: string, operations: {name: string, allowed: boolean, valid: boolean}}}
     */
    category: undefined
  },
  computed: {
    /**
     * Category Type icon Color Class
     * @return {String|string|undefined} Category Type icon Color Class
     */
    iconColorClass() {
      if (!this.category) {
        return undefined;
      }

      return (
        findCategoryTypeColorClass(this.category?.categoryType ?? -1) ??
        undefined
      );
    }
  }
};
</script>
